.dashboard-layout {
    background-color: #F9F9F9;
    height: 100vh;
}
.dashboard-navbar, .dashboard-body {
    display: grid;
    grid-template-columns: 250px 1fr;
}
.dashboard-navbar {
    height: 75px;
    background-color: #FFF;
}
.dashboard-body {
    height: calc(100vh - 75px);
}
.dashboard-logo {
    padding: 20px;
    background-color: #182433;
    color: #FFF;
}
.dashboard-logo img {
    width: 180px;
    max-width: 100%;
}
.dashboard-content {
    background-color: #FFF;
    margin-left: 15px;
    height: calc(100vh - 75px);
    overflow-y: scroll;
}
.menubar{
    display: none;
}

@media only screen and (max-width: 600px) {
    .dashboard-navbar, .dashboard-body {
        display: block;
    }
    .dashboard-navbar {
        margin-bottom: 0;
    }
    .dashboard-logo img {
        width: 200px;
    }
    .dashboard-content {
        margin-left: 0;
        margin-top: 15px;
        height: auto;
        overflow-y: auto;
        background-color: transparent;
    }
    .menubar {
        display: block;
        float: right;
        margin: 5px 10px;
    }
    .dashboard-layout {
        background-color: white;
    }
}