.add-box {background-color: #FFF;padding: 20px;box-shadow: 0px 0px 15px 0px #0000001A;margin-bottom: 30px;}
.transactions-box {box-shadow: 0px 0px 15px 0px #0000001A;}
.transactions-box > div {padding: 20px;background-color: #FFFFFF;border-bottom: 1px solid #C4C4C4DB;}
.transactions-box > div:first-child {padding: 10px 15px;background: #8393AE1A;font-weight: 500;font-size: 14px;}
.transactions-box > div:last-child {border-bottom: 0;}
.transactions-box > div > p {margin-bottom: 0;}
.transactions-box > div > p > span {display: none;font-weight: 500;}
.transactions-box p.block {color: #F0393A;cursor: pointer;font-weight: 500;}
.all-list-head, .all-list-body {display: grid;grid-template-columns: 50px 140px 1fr 1fr 80px 120px;}
.all-list-head {font-weight: 500; font-size: 14px; border: 1px solid #D1DFFF; background: #8393AE1A; color: #8393AE;}
.all-list-body p{font-size: 14px; font-weight: 500;}
.view-detail {color:#6091E2; ;font-weight: 500;cursor: pointer; text-decoration: underline;}
.modal-container {position: fixed;left: 0;top: 0;width: 100%;height: 100vh;z-index: 99;display: grid;place-items: center;}
.modal-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh;z-index: 999;background: #00000050;}
.modal-box {position: absolute;z-index: 9999;background: #FFF;border: 1px solid #EEE;border-radius: 0.3rem;box-shadow: 0px 0px 15px 0px #0000001A;width: 700px;}

.modal-header {background-color: #FAFAFA;padding: 15px 20px;font-weight: 500;display: grid;grid-template-columns: auto auto;justify-content: space-between;align-items: center;}
.modal-body {padding: 10px 20px;display: grid;grid-template-columns: 1fr 1fr;}
.modal-body > div:nth-child(odd) {padding-right: 10px;}
.modal-body > div:nth-child(even) {padding-left: 10px;}
.modal-footer {padding: 20px 20px;border-top: 1px solid #EEE;display: grid;grid-template-columns: auto auto;justify-content: space-between;}




@media only screen and (max-width: 600px) {
    .add-box {padding: 0px}
    .transactions-box {box-shadow: none;}
    .transactions-box > div:first-child {display: none;}
    .transactions-box > div {background-color: #FFF;border-bottom: 0;margin-bottom: 10px;padding: 0;border-radius: 5px;overflow: hidden;} 
    .transactions-box > div {grid-template-columns: 1fr;}
    .transactions-box > div {grid-template-columns: 1fr;}
    .transactions-box > div p {border-top: 1px solid #EEE;padding: 10px 15px;}
    .transactions-box > div p:first-child {background: #EEE;font-weight: 500;width: max-content;padding: 8px 15px;}
    .transactions-box > div p span {display: inline-block;color: #1C2347;}
    .modal-box {width: 90%;}
    .modal-footer {display: block;padding: 10px 30px;}
    .modal-footer > div {margin: 10px 0;}

}