.dashboard-counts {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 20px;
}
.dashboard-counts > div {
    display: grid;
    grid-template-columns: 70px auto;
    padding: 10px;
    align-items: center;
}
.dashboard-counts > div > img {
    width: 60px;
}
.dashboard-counts > div > div > p {
    font-size: 14px;
    color: #777;
    font-weight: 500;
}
.dashboard-counts > div > div > span {
    font-size: 22px;
    font-weight: 600;
}

@media only screen and (max-width: 1250px) {
    .dashboard-counts {
        grid-template-columns: auto auto;
    }
}

@media only screen and (max-width: 780px) {
    .dashboard-counts {
        grid-template-columns: auto;
    }
}