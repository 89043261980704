.plan-head, .plan-body {display: grid;grid-template-columns: 40px 2fr 2fr 3fr 180px;gap: 10px;font-size: 14px;padding: 15px;align-items: center;}
.plan-head {background-color: #FAFAFA;font-weight: 500;}
.plan-body {background-color: #FFFFFF;border-top: 1px solid #C4C4C4DB;}
.plan-head > p, .plan-body > p {margin-bottom: 0;}
.plan-body > p > span {display: none;font-weight: 500;}
.plan-body div {display: flex;gap: 10px;}
.plan-body div span {padding: 5px 15px;border-radius: 1rem;cursor: pointer;background-color: #EAF0F560;transition: 0.3s;}
.plan-body div span:hover {background-color: #EAF0F5;}

@media only screen and (max-width: 600px) {
    .plan-head {display: none;}
    .plan-body {display: block;border-top: 0;margin-bottom: 10px;padding: 0;border-radius: 5px;overflow: hidden;}
    .plan-body p {border-top: 1px solid #EEE;padding: 10px 15px;}
    .plan-body p:first-child {background: #EEE;font-weight: 500;width: max-content;padding: 8px 15px;}
    .plan-body p span {display: inline-block;color: #212529;}
}
