.transactions-box {box-shadow: 0px 0px 15px 0px #0000001A;}
.transactions-box > div {padding: 20px;background-color: #FFFFFF;border-bottom: 1px solid #C4C4C4DB;}
.transactions-box > div:first-child {padding: 15px 20px;background: #FAFAFA;font-weight: 500;font-size: 14px;}
.transactions-box > div:last-child {border-bottom: 0;}
.transactions-box > div > p {margin-bottom: 0;}
.transactions-box > div > p > span {display: none;font-weight: 500;}
.transactions-box p.block {color: #F0393A;cursor: pointer;font-weight: 500;}

.all-list-head, .all-list-body {display: grid;grid-template-columns: 50px 2fr 2fr 2fr 100px ;align-items: center; column-gap: 8px;}
.no-data {display: block;text-align: center;border-bottom: 0px;}

/* .view-detail-box{cursor: not-allowed; text-decoration: underline; } */

@media only screen and (max-width: 600px) {
    .transactions-box  {box-shadow: none;}
    .all-list-head {display: none;}
    .transactions-box  > div {background-color: #FFF;border-bottom: 0;margin-bottom: 10px;padding: 0;border-radius: 5px;overflow: hidden;}
    .transactions-box  > div {grid-template-columns: 1fr;}
    .transactions-box  > div p {border-top: 1px solid #EEE;padding: 10px 15px;}
    .transactions-box  > div p:first-child {background: #EEE;font-weight: 500;width: max-content;padding: 8px 15px;}
    .transactions-box  > div p span {display: inline-block;color: #1C2347;}

}




