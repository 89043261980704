.search-box {background-color: #FFF;padding: 20px;box-shadow: 0px 0px 15px 0px #0000001A;display: grid;grid-template-columns: 1fr 1fr 200px;column-gap: 15px;}
.search-box select {margin-bottom: 0;width: 300px; padding: 10px 10px;}
/* .pricing-head, .pricing-body {display: grid;grid-template-columns: 50px 3fr 100px 100px 100px 100px 2fr 220px 220px 70px; align-items: center;} */
.pricing-head, .pricing-body {display: grid;grid-template-columns: 40px 75px 310px 1fr 250px 220px 220px 50px;align-items: center;}
.pricing-head {padding: 10px 15px;font-weight: 600;background: #FFF;font-size: 14px;margin-top: 15px;border: 1px solid #6091E24D;}
.pricing-body {padding: 15px;background-color: #FFF;font-size: 14px;}
.pricing-body:nth-child(odd) {background-color: #F5F5F5;}
.pricing-body p {margin-bottom: 0;padding-right: 10px;font-size: 14px;}
.pricing-body p.post {color: #AD7A03;}
.pricing-body p.get {color: #007F31;}
.pricing-body p.post, .pricing-body p.get {text-transform: uppercase;font-weight: 500;}
.pricing-body p.post span, .pricing-body p.get span {text-transform: capitalize;}
.pricing-body p span {display: none;font-weight: 500;color: #212529;}
.pricing-body p:last-child {color: #6091E2 ;font-weight: 600 ;cursor: pointer;}
.pricing-modal-box {width: 800px;max-height: 90vh;background: #FFF;border: 1px solid #EEE;border-radius: 0.4rem;box-shadow: 0px 0px 15px 0px #0000001A;}
.pricing-modal-head {font-size: 25px;padding: 15px 25px;background-color: #F9F9F9;display: grid;grid-template-columns: auto auto;justify-content: space-between;align-items: center;}
.pricing-modal-head img {cursor: pointer;}
.pricing-modal-body {max-height: calc(90vh - 132px);overflow: hidden scroll;padding: 25px;}
.pricing-modal-footer {display: grid;grid-template-columns: auto auto;justify-content: space-between;padding: 15px 25px;border-top: 1px solid #EEE;}
.small-input {display: grid;grid-template-columns: 1fr 1fr;}
.small-input div:nth-child(odd) {padding-right: 10px;}
.small-input div:nth-child(even) {padding-left: 10px;}
.textArea {display: block;border: 1px solid #CCC;border-radius: 3px;width: 100%;padding: 8px 10px;margin-bottom: 15px;background: transparent;}
.pricing-modal-form-cnt {margin-top: 1rem;}
.export-container {position: absolute; width: 100%; height: 100vh; z-index: 99; top: 0; left: 0; display: grid; place-items: center; }
.export-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh; z-index: 999;background: #00000050;}
.export-box {position: absolute; padding: 25px; z-index: 9999; background: #FFF; width: 500px; border-radius: 5px;}
.export-box h3 {font-size: 24px;}
.export-box img[alt="times"] {float: right;cursor: pointer;}
.export-box img[alt="download"] {margin-bottom: 20px;width: 110px;}
.export-box p {margin-bottom: 0;padding: 10px;font-weight: 500;background-color: #F9F9F9;border-bottom: 1px solid #eee;display: grid;grid-template-columns: 1fr 1fr;text-align: right;}
.export-box p span {padding-left: 10px;text-align: left;color: #FF7D90;}
.export-buttons {display: grid;grid-template-columns: auto auto;justify-content: space-between;margin-top: 20px;}
.pagination {display: flow-root;text-align: center;padding: 20px;}
.categoryOption {text-transform: capitalize;}

@media only screen and (max-width: 600px) {
    .our-api-services {background-color: transparent;}
    .edit-services {grid-template-columns: 1fr;}
    .edit-services div:nth-child(1), .edit-services div:nth-child(2) {padding-right: 0;}
    .api-search-box {padding: 20px 20px 5px 20px;margin-bottom: 15px;background-color: #FFF;}
    .api-menu {display: none;}
    .api-entries {grid-template-columns: 1fr;background-color: #FFF;border-bottom: 0;margin-bottom: 10px;padding: 0;border-radius: 5px;overflow: hidden;}
    .api-entries p {border-top: 1px solid #EEE;padding: 10px 15px;}
    .api-entries p:first-child {background: #EEE;font-weight: 500;width: max-content;padding: 8px 15px;}
    .api-entries p span {display: inline-block;}
    .pricing-body:nth-child(even) {background-color: #FFF;;}
    .pricing-head {display: none;}
    .pricing-body {grid-template-columns: 1fr; background-color: #fff; border-bottom: 0; margin-bottom: 10px; padding: 0; border-radius: 5px; overflow: hidden;}
    .pricing-body p {border-top: 1px solid #eee; padding:10px 15px}
    .pricing-body p span {display:inline-block ; font-weight: 500; }
    /* .pricing-body p:nth-child(3), .pricing-body p:nth-child(4), .pricing-body p:nth-child(5), .pricing-body p:nth-child(6) {font-weight: 400;}
    .pricing-body p:nth-child(4) span, .pricing-body p:nth-child(5) span, .pricing-body p:nth-child(6) span {margin-right: 70px;}
    .pricing-body p:nth-child(3) span {margin-right: 73px;}
    .pricing-body p:nth-child(2) span {margin-right: 72px;}
    .pricing-body p:nth-child(7) span {margin-right: 49px;}
    .pricing-body p:nth-child(8) span {margin-right: 40px;}
    .pricing-body p:nth-child(9) span {margin-right: 37px;} */
    .search-box select {width: 250px}
    .search-box {padding: 0px}
    .pricing-body p:nth-child(1) {background: #EEE;font-weight: 500; width: max-content; padding: 8px 15px;}
    .api-pricing {padding:15px;} 
    .pricing-modal-box {width: fit-content}
}