.navbar {
    border-bottom: 1px solid #EEE;
    padding: 15px 20px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: end;
    
}
.wallet {
    border-radius: 0.5rem;
    min-width: 180px;
    display: inline-block;
    padding: 5px 10px;
}
.wallet:last-child {
    margin-left: 20px;
}
.wallet p {
    color: #777;
    font-size: 12px;
    line-height: 1.4;
}
.wallet span {
    color: inherit;
    font-size: 16px;
    font-weight: 600;
    display: block;
    line-height: 1.4;
    float: right;
}

@media only screen and (max-width: 600px) {
    .navbar {
        padding: 10px;
        justify-content: space-between;
    }
    .wallet:last-child {
        margin-left: 0;
    }
    .wallet p {
        font-size: 10px;
    }
    .wallet span {
        font-size: 14px;
        margin-top: 20px;
    }
}
