.dashboard-items {display: grid;grid-template-columns: repeat(2, 1fr);background: #FFF;border-radius: 5px;box-shadow: 0 0 5px 0 #DDD;padding: 20px 0;}
.dashboard-items > div {border-right: 1px solid #E4E4E4;padding: 0 20px;}
.dashboard-items > div > img {float: left;margin-right: 15px;}
.dashboard-items > div:last-child {border-right: 0;}
.dashboard-items h5 {font-size: 35px;font-weight: 600;margin-bottom: 0;line-height: 1;}
.dashboard-items p {font-size: 16px;margin-bottom: 0;color: #777777;font-weight: 500;}

@media only screen and (max-width: 600px) {
    .dashboard-items {grid-template-columns: 1fr;padding: 0 20px;}
    .dashboard-items > div {border-right: 0;border-bottom: 1px solid #E4E4E4;padding: 20px 0;}
}
