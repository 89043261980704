.reset-password-container {
    background: #FFF;
    padding: 20px;
}
.reset-password-box {
    background: #6F6EB314;
    padding: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
.reset-password-box > div:nth-child(2) {
    text-align: center;
    padding-left: 20px;
}
.reset-password-box > div:nth-child(2) img {
    width: 350px;
    max-width: 100%;
}
.reset-password-box form {
    background: #FFF;
    padding: 30px;
    max-width: 600px;
}
.reset-password-box form h5 {
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid #E7E7E6;
    padding-bottom: 5px;
    margin-bottom: 15px;
}
.hide-show-password {
    margin-right: 0.625rem;
    margin-top: -3rem;
    float: right;
    cursor: pointer;
}
.instructions {
    font-weight: 300;
    font-size: 12px;
}

@media only screen and (max-width: 900px) {
    .reset-password-box {
        display: block;
    }
    .reset-password-box > div:nth-child(2) {
        padding-top: 20px;
        padding-left: 0;
    }
}
