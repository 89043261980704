.plan-details {
    border: 1px solid #DDD;
    display: inline-block;
    padding: 0 20px 0 0;
    border-radius: 0.5rem;
    background: #FFF;
    box-shadow: 0 0 10px 0 #EEE;
    margin: 0 20px 20px 0;
}
.plan-details span {
    color: #777;
    font-weight: 500;
    padding: 10px;
    margin-right: 10px;
    display: inline-block;
    border-right: 1px solid #DDD;
    font-size: 14px;
}
.price-tab {
    margin-bottom: 25px;
}
.price-tab li {
    display: inline-block;
    border-bottom: 3px solid transparent;
    padding: 5px 10px;
    margin-right: 10px;
    cursor: pointer;
    font-weight: 500;
    color: #777;
}
.plan-head, .plan-body {display: grid; column-gap: 5px;}
.plan-head {padding: 15px 20px;background: #FAFAFA;font-weight: 500;font-size: 14px;}
.plan-body {padding: 20px;background-color: #FFFFFF;border-top: 1px solid #C4C4C4DB;}
.plan-head > p, .plan-body > p {margin-bottom: 0;}
.plan-body > p > span {display: none;font-weight: 500;}
.product-name {
    padding: 15px 20px;background-color: #FFFFFF;
    font-weight: 500;
    border-top: 1px solid #EEE;
    border-right: 1px solid #EEE;
    border-left: 1px solid #EEE;
}

@media only screen and (max-width: 600px) {
    .plan-details {margin: 0 10px 10px 0;}
    .plan-head {display: none;}
    .plan-body {display: block;border-top: 0;margin-bottom: 10px;padding: 0;border-radius: 5px;overflow: hidden;}
    .plan-body p {border-top: 1px solid #EEE;padding: 10px 15px;}
    .plan-body p:first-child {background: #EEE;font-weight: 500;width: max-content;padding: 8px 15px;}
    .plan-body p span {display: inline-block;color: #212529;}
}
