/*=====----- User accounts start -----=====*/
.user-detail-box {margin-bottom: 20px;background-color: #FFF;box-shadow: 0px 0px 15px 0px #0000001A;padding-right: 10px;}
.user-detail-box > div {padding-right: 10px;display: grid;grid-template-columns: 100px 1fr;align-items: center;padding-bottom: 25px;}
.user-detail-box > div:first-child > p {padding: 10px 0;margin-left: 10px;margin-bottom: 0;border-bottom: 1px solid #C4C4C4DB;}
.user-detail-box > div img {margin-left: 10px;}
.user-detail-box > div > p > span {font-weight: 600;font-size: 14px;}
.user-details {display: grid;grid-template-columns: 1fr 1fr auto;padding-left: 10px;justify-content: space-between;}
.id {font-weight: 500;font-size: 14px;background-color: #F3F5FF;border-bottom: 1px solid #F3F5FF;margin-left: 0 !important;text-align: center;padding: 12px 0;}
.user-details p span {display: block;margin-left: 0;font-weight: 600;font-size: 16px;}
.user-details p span.green {color: #06BF24;}
.user-details p span.red {color: #F0393A;}
.user-details > p {font-weight: 400;margin: 5px 0;}
.user-details > div {margin-top: 5px;}
.no-user-card {text-align: center;box-shadow: 0px 0px 15px 0px #0000001A;background-color: #FFF;padding: 20px;margin-bottom: 20px;}
.no-user-card img {width: 180px;}
.no-user-card p {font-size: 18px;font-weight: 500;margin: 20px 0 0 0;color: #6B6B6B;text-transform: uppercase;}
.api-logs-head, .api-logs-entries {display: grid;grid-template-columns: 50px 1fr 1fr 150px 80px;padding: 10px 20px;}
.api-logs-head {background-color: #FAFAFA;font-weight: 500;}
.api-logs-entries {background: #FFF;border-bottom: 1px solid #C4C4C4DB;}
.api-logs-entries:last-child {border-bottom: 0;}
.api-logs-head p, .api-logs-entries p {margin-bottom: 0;}
.api-logs-entries p.green {color: #06BF24;}
.api-logs-entries p.red {color: #F0393A;}
.api-logs-entries>p>span{display:none;}

/*********** API Price Begins  ************/
.api-price-head, .api-price-entries {display: grid;grid-template-columns: 40px 180px 180px 180px 2fr 120px 120px 120px 150px 60px;padding: 10px 20px;align-items: center; column-gap: 5px;}
.api-price-head {background-color: #FAFAFA;font-weight: 500;}
.api-price-entries {background: #FFF;border-bottom: 1px solid #C4C4C4DB;}
.api-price-entries p span {display: none;}
.api-price-entries:last-child {border-bottom: 0;}
.api-price-head p, .api-price-entries p {margin-bottom: 0;}
.api-price-entries p button {border-radius: 3px; background: rgba(15, 13, 129, 0.60); color: white; padding: 10px;}
/*********** API Price End  ************/

.input {padding-right: 20px;}
.input input {margin-bottom: 0;}
.save {font-weight: 500;cursor: pointer; display: inline-block !important;}
.save-disabled {font-weight: 500;cursor: not-allowed;color: #AAA;}
/* .edit-box{background: #FFF;  padding: 30px; width: 530px;}
.edit-box p{padding: 12px 0px;}
.edit-box span{font-weight: 600;} */
/* .save{font-weight: 500;cursor: pointer; display:inline-block;border-radius: 3px; background: rgba(15, 13, 129, 0.60);margin-right: 20px; border: 1px solid #6F6EB3;}
.save span{ padding: 10px 30px;color: white !important;}
.cancel{font-weight: 500;cursor: pointer; display:inline-block; border-radius: 3px; padding: 10px 30px;border: 1px solid #6F6EB3;}
.cancel span{ padding: 10px 30px; }
.modal-box{position: absolute; z-index: 9999; left: 35%;} */
/*=====----- User accounts end -----=====*/

/*=====----- User detail start -----=====*/
.back-link {margin-bottom: 10px;}
.back-link a {text-decoration: none;}
.user-detail-main-box {background-color: #FFF;box-shadow: 0px 0px 15px 0px #0000001A;min-height: calc(100vh - 150px);}
.image-content-box {display: grid;grid-template-columns: 110px 1fr;padding: 30px;}
.image-content-box > div:first-child > img {width: 80px;}
.user-information {display: grid;grid-template-columns: 1fr 1fr;}
.user-information > p {margin: 5px 0;font-weight: 500;font-size: 18px;}
.user-information > p span {font-weight: 400;}
.menu-points {cursor: pointer;display: grid;grid-template-columns: repeat(10, auto);gap: 20px;padding-left: 20px;justify-content: start;border-bottom: 1px solid #C4C4C4DB;}
.menu-points p {font-weight: 500;margin-bottom: 0;padding: 5px 0;font-size: 14px;border-bottom: 2px solid transparent;}
.billing-info {padding: 20px 30px;display: grid;grid-template-columns: repeat(4, auto);justify-content: space-between;}
.billing-info p span {font-weight: 600;font-size: 40px;}
.billing-info p {font-weight: 500;font-size: 16px;margin-bottom: 0;}
.api-hits {color: #6B6B6B;}
.debit {color: #F0393A}
.credit { color: #06BF24;}
.refund {color: #1C2347;}
.services-menu, .transaction-data-menu {display: grid;grid-template-columns: 50px 230px 130px 1fr 1fr 1fr 70px;padding: 10px 20px; column-gap: 10px;}
.services-menu {background-color: #FAFAFA;}
.services-menu p {margin-bottom: 0;font-weight: 500;}
.transaction-data-menu {border-bottom: 1px solid #C4C4C4DB;}
.transaction-data-menu:last-child {border-bottom: 0;}
.transaction-data-menu p {margin-bottom: 0;font-weight: 400;font-size: 16px;}
.transaction-data-menu p.red {color: #F0393A;}
.transaction-data-menu p.green {color: #06BF24;}
.transaction-data-menu p span {display: none;}

/* CREDITS */
.credits-account {padding: 20px;}
.available-credits {display: grid; grid-template-columns: 200px 50px 1fr;}
.add-credits {display: grid; grid-template-columns: 200px 50px 1fr 190px; align-items: baseline; margin-bottom: 0;}
.available-credits >:first-child {font-weight: 500;font-size: 16px;color: #1C2347;}
.available-credits :nth-child(2) {font-weight: 700;font-size: 16px;color: #000000;}
.available-credits :nth-child(3) {font-weight: 700;font-size: 16px;} 
.add-credits > :first-child {font-weight: 500;font-size: 16px;color: #1C2347;}
.add-credits :nth-child(2) {font-weight: 500;font-size: 16px;color: #000000;}
.add-credits :nth-child(3) {width: 500px;}
.api-wallet {background-color: #FAFAFA;padding: 15px 40px;font-weight: 600;font-size: 18px;margin-bottom: 20px;}
.api-credit-tab {display: grid; grid-template-columns: repeat(2, 400px);margin-bottom: 15px;}
.balance-tab {border: 2px solid #EEE;border-radius: 0.5rem;padding: 20px;font-weight: 500;margin-left: 15px;}
.balance-tab span {font-weight: 700;font-size: 50px;}
.balance-tab h3 {font-weight: 500;font-size: 20px;margin-bottom: 0;}
.credit-tab {border: 2px solid #eee; border-radius: 0.5rem; padding: 20px;font-weight: 500;margin-left: 15px;}
.credit-tab h3 {font-weight: 500;font-size: 20px;}

/* PRODUCT */
.settings-heading {background-color: #FAFAFA;padding: 15px 40px;font-family: 'Rubik';font-weight: 400;font-size: 18px;}
.various-product {padding: 15px 40px;display: grid;grid-template-columns: 1fr 300px 50px; border-top: 1px solid #C4C4C460;font-weight: 500;font-size: 16px;}
.various-product > div:nth-child(2) {display: grid;grid-template-columns: auto auto;}
.various-product > span.disabled {color: #CCC;}
.delete-user {padding: 15px 40px;border-top: 1px solid #C4C4C460;}
.delete-user p {font-weight: 500;}

/* KYC */
.kyc-details{background-color: #FAFAFA;padding: 15px 40px;font-weight: 500;font-size: 18px;}
.kyc-info p{ display: grid; grid-template-columns: 250px 1fr; padding: 10px 40px 15px 40px; margin-bottom: 0;}
.kyc-info > p > span:first-child {font-weight: 600;}
.pan-status{background-color:#EDFFF0; padding-top: 5px; padding-bottom: 5px;}
.aadhaar-status{background-color:#EDFFF0; padding-top: 5px; padding-bottom: 5px;}
/*=====----- User detail end -----=====*/

/* ===== ----- Filter modal start ----- ===== */
.filter-icon span{display: grid; grid-template-columns: 20px 1fr 30px; align-items: center;}
.filter-form{position: absolute; padding: 25px; z-index: 9999; right: 0; height: 100vh; background: #FFF; width: 400px; max-width: 100%; border-radius: 5px;}
.filter-icon [alt = 'times'] {cursor: pointer;}
.transaction-bar span{cursor: pointer;}
.filter-container {position: absolute; width: 100%; height: 100vh; z-index: 99; top: 0; left: 0; place-items: right; }
.filter-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh; z-index: 999;background: #00000050;}
.clear-filter {float: right;margin-top: 26px;color: #FF7D90;font-weight: 500;font-size: 18px;text-decoration: underline;cursor: pointer;}
/* ===== ----- Filter modal end ----- ===== */

/* ===== ----- Export modal start ----- ===== */
.export-container {position: absolute; width: 100%; height: 100vh; z-index: 99; top: 0; left: 0; display: grid; place-items: center; }
.export-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh; z-index: 999;background: #00000050;}
.export-box {position: absolute; padding: 25px; z-index: 9999; background: #FFF; width: 500px; border-radius: 5px;}
.export-box h3 {font-size: 24px;}
.export-box img[alt="times"] {float: right;cursor: pointer;}
.export-box img[alt="download"] {margin-bottom: 20px;width: 110px;}
.export-box p {margin-bottom: 0;padding: 10px;font-weight: 500;background-color: #F9F9F9;border-bottom: 1px solid #eee;display: grid;grid-template-columns: 1fr 1fr;text-align: right;}
.export-box p span {padding-left: 10px;text-align: left;color: #FF7D90;}
.export-buttons {display: grid;grid-template-columns: auto auto;justify-content: space-between;margin-top: 20px;}
/* ===== ----- Export modal end ----- ===== */

/* ===== ----- Export modal start ----- ===== */
.export-csv-container {position: absolute; width: 100%; height: 100vh; z-index: 99; top: 0; left: 0; display: grid; place-items: center; }
.export-csv-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh; z-index: 999;background: #00000050;}
.export-csv-box {position: absolute; padding: 25px; z-index: 9999; background: #FFF; width: 500px; border-radius: 5px;}
.export-csv-box h3 {font-size: 24px;}
.export-csv-box img[alt="times"] {float: right;cursor: pointer;}
.export-csv-box img[alt="download"] {margin-bottom: 20px;width: 110px;}
.export-csv-box p {margin-bottom: 0;padding: 10px;font-weight: 500;background-color: #F9F9F9;border-bottom: 1px solid #eee;display: grid;grid-template-columns: 1fr 1fr;text-align: right;}
.export-csv-box p span {padding-left: 10px;text-align: left;color: #FF7D90;}
.export-csv-buttons {display: grid;grid-template-columns: auto auto;justify-content: space-between;margin-top: 20px;}
/* ===== ----- Export modal end ----- ===== */

/*=====----- Settings start -----=====*/
.header-settings {padding:15px 40px;background-color: #AAAAAA10;color: #212529;font-size: 18px;font-weight: 700;}
.settings-body {padding: 15px 40px;display: grid;grid-template-columns: 1fr 300px 50px;border-top: 1px solid #C4C4C460;font-weight: 500;font-size: 16px;}
.settings-body > div {display: grid;grid-template-columns: auto auto}
/*=====----- Settings end -----=====*/




@media only screen and (max-width: 1600px) {
.services-menu, .transaction-data-menu {display: grid; grid-template-columns: 50px 230px 130px 2fr 1fr 1fr 70px;padding: 10px 20px; column-gap: 10px;}
}

@media only screen and (max-width: 1200px) {
    .services-menu, .transaction-data-menu {display: grid; grid-template-columns: 40px 220px 120px 1fr 100px 100px 70px; column-gap: 10px;}
    }
@media only screen and (min-width: 2200px) {.services-menu, .transaction-data-menu {display: grid;
    grid-template-columns: 110px 2fr 2fr 3fr 1fr 1fr 1fr;}}

/* @media only screen and (max-width: 600px) {.services-menu {display: none;}
        .transaction-data-menu {grid-template-columns: 1fr;padding: 0;margin-bottom: 15px;border-bottom: 0;}
        .transaction-data-menu p {border-top: 1px solid #EEE;padding: 10px 15px;font-size: 14px;}
        .transaction-data-menu p:first-child {background: #F3F5FF;font-weight: 500;padding: 8px 15px;}
        .transaction-data-menu p span {display: inline-block;font-weight: 600;color: #1C2347;}} */



@media only screen and (max-width: 600px) {
    .image-content-box > div:first-child > img {margin-bottom: 10px;}
    .settings-body{display: block;}
    .user-detail-box {margin-bottom: 10px;padding-right: 0;}
    .user-detail-box > div {grid-template-columns: 1fr;padding-right: 0;padding-bottom: 0;}
    .user-details {grid-template-columns: 1fr;padding-left: 0;}
    .id {text-align: left;padding: 10px 15px;}
    .user-detail-box > div:first-child > p {padding: 10px 15px;margin-left: 0;}
    .user-details > p {margin-bottom: 0;font-weight: 500;padding: 10px 15px;border-top: 1px solid #EEE;text-align: left;font-size: 16px;}
    .user-details > p > span {display: inline-block;font-weight: 400;margin-left: 10px;}
    .user-details button {width: calc(100% - 30px);margin: 5px 15px 15px 15px;}
    .filter-form {height: 100%;width: 100%;border-radius: 0;}
    .user-detail-main-box {min-height: calc(100vh - 191px);}
    .image-content-box {grid-template-columns: 1fr;padding: 20px;}
    .user-information {grid-template-columns: 1fr;}
    .user-information > p {font-size: 16px;}
    .services-menu {display: none;}
    .transaction-data-menu {grid-template-columns: 1fr;padding: 0;margin-bottom: 15px;border-bottom: 0;}
    .transaction-data-menu p {border-top: 1px solid #EEE;padding: 10px 15px;font-size: 14px;}
    .transaction-data-menu p:first-child {background: #F3F5FF;font-weight: 500;padding: 8px 15px;}
    .transaction-data-menu p span {display: inline-block;font-weight: 600;color: #1C2347;}

    .api-logs-head {display: none;}
    .api-logs-entries {grid-template-columns: 1fr;padding: 0; border-bottom: 0px;}
    .api-logs-entries > p {padding: 8px 15px;border-top: 1px solid #EEE; font-size: 14px; }
    .api-logs-entries  p  span{display:inline-block; font-weight: 600;color: #1C2347;}
    .api-logs-entries > p:first-child {font-weight: 500;background: #F3F5FF; border-top: 0px; }

    .api-price-head{display: none;}
    .api-price-entries {grid-template-columns: 1fr; padding: 0px; border-bottom: 0px;}
    .api-price-entries p span {display: inline-block;  font-weight: 600;color: #1C2347;}
    .api-price-entries p:first-child {background: #F3F5FF;font-weight: 500}
    .api-price-entries p{padding: 8px 15px;border-top: 1px solid #EEE; font-size: 14px; }

    

    .billing-info {padding: 20px;grid-template-columns: repeat(2, auto);}
    .menu-points {padding-left: 0;grid-template-columns: repeat(1, 1fr);}
    .menu-points p {width: max-content;}
    .credits-account {background-color: transparent;}
    .available-credits {grid-template-columns: 1fr;}
    .add-credits {grid-template-columns: 1fr;}
    .available-credits > span:nth-child(2), .add-credits > span:nth-child(2) {display: none;}
    .available-credits :nth-child(3) {font-weight: 600;font-size: 40px;line-height: 1;}
    .add-credits > span:nth-child(3) {width: auto;}
    .enable-disable {padding: 15px;}
    .various-product {padding: 15px;grid-template-columns: 1fr;}
    .various-product > span:nth-child(1) {font-size: 18px;margin-bottom: 5px;}
    .kyc-details {padding: 15px;}
    .kyc-info p {grid-template-columns: 1fr;padding: 15px;border-bottom: 1px solid #EEE;}
    .api-credit-tab {grid-template-columns: 1fr;}
    .balance-tab {margin: 0 15px;}
    .credit-tab {margin: 15px;}
}


/* API Status starts here */
.plan-head, .plan-body {display: grid;grid-template-columns: 40px 2fr 2fr 3fr 180px;gap: 10px;font-size: 14px;padding: 15px;align-items: center;}
.plan-head {background-color: #FAFAFA;font-weight: 500;}
.plan-body {background-color: #FFFFFF;border-top: 1px solid #C4C4C4DB;}
.plan-head > p, .plan-body > p {margin-bottom: 0;}
.plan-body > p > span {display: none;font-weight: 500;}
.plan-body div {display: flex;gap: 10px;}
.plan-body div span {padding: 5px 15px;border-radius: 1rem;cursor: pointer;background-color: #EAF0F560;transition: 0.3s;}
.plan-body div span:hover {background-color: #EAF0F5;}
.plan-body div span.hidden {background-color: #ff00001A;color: #ff0000;}
.plan-body div span.visible {background-color: #0080001A;color: #008000;}
.update-loader {background-color: #0000001A;position: absolute;width: 100vw;height: 100vh;left: 0;top: 0;display: grid;place-items: center;}

@media only screen and (max-width: 600px) {
    .plan-head {display: none;}
    .plan-body {display: block;border-top: 0;margin-bottom: 10px;padding: 0;border-radius: 5px;overflow: hidden;}
    .plan-body p {border-top: 1px solid #EEE;padding: 10px 15px;}
    .plan-body p:first-child {background: #EEE;font-weight: 500;width: max-content;padding: 8px 15px;}
    .plan-body p span {display: inline-block;color: #212529;}
}
/* API Status ends here */