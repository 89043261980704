.standardCardCnt {
  border: 0.435px solid #ed9630;
  border-radius: 4.35px;
  background: #fff;
  max-width: 355px;
  padding: 2rem;
}

.priceSelection {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.standardCardMonthlyPrice {
  border-radius: 5px 0px 0px 5px;
  border: 2px solid #ed9630;
  background: #fff;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.standardCardYearlyPrice {
  border-radius: 0px 5px 5px 0px;
  border: 2px solid #ed9630;
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.standardCardBuyBtn {
  width: 100%;
  color: #fff;
  padding: 12px;
  border-radius: 68px;
  border: 1px solid #ed9630;
  background: #ed9630;
}

.standardCardTalkBtn {
   margin-top: 14px;
   width: 100%;
   color: #ed9630;
   padding: 12px;
   border-radius: 68px;
   border: 1px solid #ed9630;
   background: #fff;
 }
 

.premiumCardCnt {
  border: 0.435px solid #4733c6;
  border-radius: 4.35px;
  background: #fff;
  max-width: 355px;
  padding: 2rem;
}

.premiumCardMonthlyPrice {
  border-radius: 5px 0px 0px 5px;
  border: 2px solid #4733c6;
  background: #fff;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.premiumCardYearlyPrice {
  border-radius: 0px 5px 5px 0px;
  border: 2px solid #4733c6;
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.premiumCardBuyBtn {
  width: 100%;
  color: #fff;
  padding: 12px;
  border-radius: 68px;
  border: 1px solid #4733c6;
  background: #4733c6;
}

.premiumCardTalkBtn {
   margin-top: 14px;
   width: 100%;
   color: #4733c6;
   padding: 12px;
   border-radius: 68px;
   border: 1px solid #4733c6;
   background: #fff;
 }

.proCardCnt {
  border: 0.435px solid #a932e0;
  border-radius: 4.35px;
  background: #fff;
  max-width: 355px;
  padding: 2rem;
}

.proCardMonthlyPrice {
  border-radius: 5px 0px 0px 5px;
  border: 2px solid #a932e0;
  background: #fff;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.proCardYearlyPrice {
  border-radius: 0px 5px 5px 0px;
  border: 2px solid #a932e0;
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.proCardBuyBtn {
  width: 100%;
  color: #fff;
  padding: 12px;
  border-radius: 68px;
  border: 1px solid #a932e0;
  background: #a932e0;
}

.proCardTalkBtn {
   margin-top: 14px;
   width: 100%;
   color: #a932e0;
   padding: 12px;
   border-radius: 68px;
   border: 1px solid #a932e0;
   background: #fff;
 }
