.add-user-manually-container {
    background: #FFF;
    padding: 20px;
}
.add-user-manually-container > h5 {
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid #E7E7E6;
    padding-bottom: 5px;
    margin-bottom: 15px;
}
.add-user-manually-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.add-user-manually-content > div:nth-child(odd) {
    padding-right: 10px;
}
.add-user-manually-content > div:nth-child(even) {
    padding-left: 10px;
}
.decide-access {
    background-color: #EFEFFF69;
    padding: 15px;
    margin-bottom: 20px;
}
.decide-access > h5 {
    font-weight: 500;
}
.decide-access > div > div {
    margin-right: 20px;
    display: inline-block;
    margin-top: 10px;
}
.account-created {
    background: #FFF;
    border-radius: 0.5rem;
    padding: 30px;
    width: 530px;
    text-align: center;
}
.account-created i {
    font-size: 50px;
}
.account-created h5 {
    font-size: 20px;
    font-weight: 500;
    margin-top: 20px;
}
.account-created hr {
    margin: 10px 0;
}
.account-created p {
    margin-bottom: 25px;
}