#banner-image {background-image: url(./banner.png); background-size: 100% 100%;}
.already-account {display: grid; grid-template-columns: auto auto; justify-content: space-between; padding: 20px 0; align-items: center;color: #FFF;}
.already-account  > div:first-child img {width: 200px;}
.already-account  > div:last-child span {color: #FF7D90; cursor: pointer;}
.signup-button button{width: 180px;}
.own-platform {color: #FFFFFF; text-align: center; padding: 20px 20px 240px 20px;}
.own-platform h4{font-size: 54px;font-weight: 600; margin: 0;}
.own-platform h6{font-size: 20px;font-weight: 600; margin: 0;}
.signup-menu {display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;padding: 0 50px 50px 50px;}
.signup-menu span {color: #929191;; font-size: 14px; font-weight: 500;}
.signup-menu span.active {color: #FF7D90;}
.basic-details form {display: grid;grid-template-columns: 1fr 1fr;}
.basic-details form > div:nth-child(odd) {margin-right: 10px;}
.basic-details form > div:nth-child(even) {margin-left: 10px;}
#signup-form {padding: 20px;}
.whitelabel-heading {color: #1C2347;font-size: 20px;font-weight: 600;margin-top: 10px;margin-bottom: 40px;}
.step-heading {color: #1C2347;font-size: 18px;font-weight: 600; margin-bottom: 5px;}
.step-subheading {color: #929191;font-size: 16px;margin-bottom: 25px;}
.signup-button {text-align: right;padding-top: 20px;}

/* pricing & payment starts here */
.pricing-payment {display: grid;grid-template-columns: 1fr 1fr 1fr;}
.pricing-payment > div {border-radius: 0.3rem;background: #FFF;padding: 20px;}
.pricing-payment > div:nth-child(1) {margin-right: 20px;}
.pricing-payment > div:nth-child(2) {margin-left: 10px;margin-right: 10px;}
.pricing-payment > div:nth-child(3) {margin-left: 20px;}
.price-heading {margin-bottom: 0;font-size: 18px;font-weight: 600;display: grid;grid-template-columns: repeat(2, auto);align-items: center;justify-content: start;}
.price-heading img {width: 20px;margin-right: 5px;}
.price-subheading {color: #929191;font-size: 12px;font-weight: 500;margin: 15px 0;}
.plan-details {margin-bottom: 20px;}
.plan-details p {color: #929191;font-size: 12px;font-weight: 500;margin-bottom: 0;}
.plan-details h4 {font-size: 26px;font-weight: 500;margin-bottom: 0;}
.plan-details h4 span {color: #929191;font-size: 16px;}
.plan-validity {display: grid;grid-template-columns: repeat(2, 1fr);margin-bottom: 20px;}
.plan-validity > div {border: 1px solid #E7E7E7;background: #E7E7E7;padding: 10px;cursor: pointer;}
.plan-validity > div:nth-child(1) {border-radius: 0.5rem 0 0 0.5rem;}
.plan-validity > div:nth-child(2) {border-radius: 0 0.5rem 0.5rem 0;}
.plan-validity > div.selected {background: #FFF;padding: 10px;}
.plan-validity > div > h6 {font-size: 12px;margin-bottom: 0;}
.plan-validity > div > p {font-size: 12px;margin: 3px 0 -5px 0;color: #929191;}
.plan-validity > div > span {color: #24B056;font-size: 12px;}
.plan-features {color: #929191;margin-bottom: 20px;}
.plan-features h5 {font-size: 14px;font-weight: 500;margin-bottom: 10px;}
.plan-features p {font-size: 12px;margin-bottom: 10px;display: grid;grid-template-columns: 15px auto;align-items: center;}
.plan-features p.highlighted {color: #212529;}
.plan-buttons > button {width: 100%;padding: 10px;border-radius: 5rem;text-align: center;margin-top: 15px;}
/* pricing & payment ends here */

.domain-details{margin-top: 20px;}
.website-mention {display: grid; grid-template-columns: 1fr auto; align-items: center;}
.website-mention span {padding-left: 5px;}
.suggestions {list-style-type: none;margin-bottom: 0;padding-left: 0;}
.suggestions li {display: inline-block;padding-right: 20px;}
.suggestions  > li:first-child {color: #FF7D90;font-weight: 600;font-size: 14px; text-decoration: underline; cursor: pointer;}
.suggestions  > li:last-child {color: #777;font-weight: 600;font-size: 14px;}
.suggestions  > li:last-child span{color: #000; font-weight: 600;font-size: 14px;}
.domain-button{text-align: right; } 
.domain-button button {width: 200px; } 
.color-type {display: grid;grid-template-columns: repeat(3, auto);margin-top: 10px;justify-content: space-between;}
.color-type > div {display: grid;grid-template-columns: auto 60px 200px;align-items: center;justify-content: start;}
.color-type > div > input {margin-bottom: 0;}
.color-type > div > input:nth-child(2) {margin-bottom: 0;margin: 0 10px;width: calc(100% - 20px);height: 100%;border: 0px;}
.upload-button {text-align: right;margin-top: 30px;} 
.upload-button button {width: 200px;}
.smtp-details form {display: grid;grid-template-columns: 1fr 1fr;}
.smtp-details form > div:nth-child(odd) {margin-right: 10px;}
.smtp-details form > div:nth-child(even) {margin-left: 10px;}
.smtp-buttons button {float: right;width: 150px;color: #FF7D90;}
.smtp-buttons button:hover {color: #FF7D90;}
.admin-dashboard span{color: #777;font-size: 14px;font-weight: 500;}
.admin-dashboard{margin-top: 30px;}
.admin-dashboard h3{color: #1C2347;font-size: 16px;font-weight: 600;}
.user-dashboard span{color: #168FC1;font-size: 14px;font-weight: 500;}
.user-dashboard{margin-top: 30px;}
.user-dashboard h3{color: #1C2347;font-size: 16px;font-weight: 600;}
.admin-box {padding: 15px; display: grid; grid-template-columns: auto auto;align-items: center;justify-content: space-between;border-radius: 0.3rem;border: 1px solid #c4c4c4db;background: #ffffffdb;}
.user-box {padding: 15px; display: grid; grid-template-columns: auto auto;justify-content: space-between;border-radius: 0.3rem;border: 1px solid #c4c4c4db;background: #ffffffdb;}
.user-box img {cursor: pointer;}
.note{margin-top: 15px; color: #FF7D90;font-size: 14px;font-weight: 400;}
.whitelabel-link h3{color: #1C2347;font-size: 16px;font-weight: 600;}
.whitelabel-link p{color: #777;font-size: 14px;font-weight: 400;}
label span {color: #777;font-size: 10px;font-weight: 500;}
.invincibleocean-domain {color: #3F4254;font-weight: 500;}
.details{background: #FFF;box-shadow: 0px 0px 30px 0px #0000001F; padding: 30px 40px; margin-top: -120px;}

/* Pop-up  */
.whitelabel-container {position: fixed; left: 0; top: 0; width: 100%; height: 100vh; z-index: 99; display: grid; place-items: center;}
.whitelabel-background {position: absolute; left: 0; top: 0; width: 100%; height: 100vh; z-index: 999; background: #00000050;}
.pop-up-form-whitelabel {position: absolute; z-index: 9999; background: #FFF; padding: 40px 30px; width: 450px; opacity: 2; max-width: 100%; border-radius: 5px; overflow: hidden; text-align: center; overflow: hidden;}
.pop-up-form-whitelabel img {width: 160px;}
.pop-up-form-whitelabel h4 {margin: 15px 0 10px 0;font-size: 20px;font-weight: 500;}
.pop-up-form-whitelabel p {color: #929191;}

@media only screen and (max-width: 900px) {
    .already-account{display: block;}
    .already-account  > div:nth-child(2){    margin-top: 14px; font-size: 18px; padding-left: 14px;}
    .own-platform {padding-bottom: 140px;}
    #signup-form {transform: translateY(120px);}
    .basic-details form {display:block}
    .basic-details form > div:nth-child(even) {margin-left: 0px;}
    .basic-details form > div:nth-child(odd) {margin-right: 0px;}
}