.registration-steps-container {
    width: calc(100% + 80px);
    margin-left: -40px;
}
.registration-steps-background {
    height: 8px;
    background-color: #EEE;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr 2fr 1fr;
}
.registration-steps-background span.active {
    background-color: #FF7D90;
}
.registration-steps {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin-top: -10px;
    margin-bottom: 50px;
}
.registration-steps li {
    text-align: center;
    color: #929191;
    font-size: 14px;
    font-weight: 500;
}
.registration-steps li.active {
    color: #FF7D90;
}
.registration-steps li.active span {
    background-color: #FF7D90;
}
.registration-steps li span {
    height: 25px;
    width: 25px;
    border-radius: 5rem;
    margin-top: -6.5px;
    background-color: #EEE;
    display: inline-block;
}
.registration-steps li p {
    margin-bottom: 0;
}