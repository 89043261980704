.verify-mail {
    display: grid;
    height: 100vh;
    align-content: center;
    text-align: center;
    justify-items: center;
}
.verify-mail img {
    width: 100px;
}
.verify-mail h4 {
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
}