.transactions-box {box-shadow: 0px 0px 15px 0px #0000001A;}
.transactions-box > div:nth-child(2) {border-top: 0;}
.transactions-head, .transactions-body {display: grid; column-gap: 8px; word-break: break-all;}
.transactions-head {padding: 15px 20px;background: #FAFAFA;font-weight: 500;font-size: 14px;}
.transactions-body {padding: 20px;background-color: #FFFFFF;border-top: 1px solid #C4C4C4DB;}
.transactions-head > p, .transactions-body > p {margin-bottom: 0;}
.transactions-body > p > span {display: none;font-weight: 500;}
.transactions-body p.red {color: #F0393A;}
.transactions-body p.green {color: #06BF24;}

.gridHead, .gridBody{grid-template-columns: 40px 1fr; gap:8px; align-items: center;}
.gridHead >div, .gridBody>div {display: grid; grid-template-columns:220px 100px 1fr 1fr 110px 70px 60px; gap:8px}
.gridBody span {display: none;}

.deleted-account {color: #999;}
.filter-icon span{display: grid; grid-template-columns: 20px 1fr 30px; align-items: center;}
.filter-form{position: absolute; padding: 25px; z-index: 9999; right: 0; height: 100vh; background: #FFF; width: 400px; max-width: 100%; border-radius: 5px;}
.filter-icon [alt = 'times'] {cursor: pointer;}
.filter-container {position: absolute; width: 100%; height: 100vh; z-index: 99; top: 0; left: 0; place-items: right; }
.filter-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh; z-index: 999;background: #00000050;}
.clear-filter {float: right;margin-top: 26px;color: #FF7D90;font-weight: 500;font-size: 18px;text-decoration: underline;cursor: pointer;}
.export-container {position: absolute; width: 100%; height: 100vh; z-index: 99; top: 0; left: 0; display: grid; place-items: center; }
.export-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh; z-index: 999;background: #00000050;}
.export-box {position: absolute; padding: 25px; z-index: 9999; background: #FFF; width: 500px; border-radius: 5px;}
.export-box h3 {font-size: 24px;}
.export-box img[alt="times"] {float: right;cursor: pointer;}
.export-box img[alt="download"] {margin-bottom: 20px;width: 110px;}
.export-box p {margin-bottom: 0;padding: 10px;font-weight: 500;background-color: #F9F9F9;border-bottom: 1px solid #eee;display: grid;grid-template-columns: 1fr 1fr;text-align: right;}
.export-box p span {padding-left: 10px;text-align: left;}
.export-buttons {display: grid;grid-template-columns: auto auto;justify-content: space-between;margin-top: 20px;}
.user-details {padding: 10px 5px;border-bottom: 1px solid #EEE;}
.user-details:nth-child(6) {border-bottom: 0;margin-bottom: 20px;}
.transaction-tabs {
    margin-bottom: 30px;
}
.transaction-tabs li {
    display: inline-block;
    padding: 5px 10px;
    cursor: pointer;
    user-select: none;
    font-weight: 500;
    color: #777;
    border-bottom: 3px solid transparent;
}

.filter-action {display: grid; justify-content: end;}
.filter-action div {cursor: pointer;}

/* Filter Pop-up starts*/
.filter-icon [alt = 'times'] {cursor: pointer;}
.filter-icon span{display: grid; grid-template-columns: 20px 1fr 30px; align-items: center;}
.filter-container {position: absolute; width: 100%; height: 100vh; z-index: 99; top: 0; left: 0; place-items: right; }
.filter-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh; z-index: 999;background: #00000050;}
.clear-filter {float: right;margin-top: 26px;color: #FF7D90;font-weight: 500;font-size: 18px;text-decoration: underline;cursor: pointer;}
.filter-form{position: absolute; padding: 25px; z-index: 9999; right: 0; height: 100vh; background: #FFF; width: 400px; max-width: 100%; border-radius: 5px;}
/* Filter Pop-up ends*/

@media only screen and (max-width: 1650px) {
.gridHead {display: none;}
.gridBody>div {display: grid; grid-template-columns:2fr 1fr 2fr 2fr;}
.gridBody span {display: block; font-weight: 600;font-size: 14px;}
/* .gridBody>div span:first-child  {display: inline-block;} */
.transactions-body {border-top: 0px; box-shadow: 0px 0px 15px 0px #0000001A; margin-bottom: 10px; border-radius: 5px}
.transactions-body p span {color: #1C2347;}
}

@media only screen and (max-width: 1550px) {
.gridBody>div {display: grid; grid-template-columns:220px 130px 2fr 2fr;}
}
@media only screen and (max-width: 1450px) {
.gridBody>div {display: grid; grid-template-columns:1fr 120px 1fr;}
}
@media only screen and (max-width: 1200px) {
.gridBody>div {display: grid; grid-template-columns:1fr 1fr ;}
}

@media only screen and (max-width: 1080px) {
    .transactions-box {box-shadow: none;}
    .gridBody>div {display: block;}
    .transactions-body {display: block;border-top: 0;margin-bottom: 10px;padding: 0;border-radius: 5px;overflow: hidden;}
    .transactions-body p {border-top: 1px solid #EEE;padding: 10px 15px;}
    .transactions-body >p:first-child {background: #EEE;font-weight: 500;width: max-content;padding: 8px 15px;}
    .transactions-body p span {display: inline-block;color: #1C2347;}
}


@media only screen and (max-width: 600px) {
    .filter-container {position: fixed;}
    .filter-form {height: 100%;width: 100%;border-radius: 0;}
    .transaction-tabs li {font-size: 12px;padding: 2px 5px;border-bottom: 1px solid transparent;}
}
