.admin-login-page {height: 100vh;display: grid;grid-template-columns: 3fr 2fr;align-items: center;justify-content: center;}
.admin-login-page > div:first-child {border-right: 1px solid #CCC;height: 80vh;display: grid;align-items: center;align-content: center;background-image: url('./background.png');height: 100vh;color: #FFF;padding: 0 50px;}
.admin-login-page > div:first-child h1 {font-size: 30px;font-weight: 500;}
.admin-login-page > div:first-child img {max-width: 700px;margin: 80px auto 40px auto;}
.admin-login-page > div:first-child p {margin: auto;}
.admin-login-box {text-align: center;}
.admin-login-box img {width: 200px;margin-bottom: 15px;}
.login-form {width: 450px;max-width: 100%;display: inline-block;box-shadow: 0px 0px 20px 0px #00000026;padding: 30px;border-radius: 0.5rem;text-align: left;}
.login-form p span {color: #FF7D90;font-weight: 600;font-size: 18px;}
.login-form > p {font-weight: 600;font-size: 18px;border-bottom: 1px solid #E7E7E6;padding-bottom: 10px;margin-bottom: 20px;}
.login-form > form > button {margin-top: 15px;}
.terms-conditions {font-size: 14px; margin-top: 12px;}
.terms-conditions a {text-decoration: underline;}
.brand-logos {display: grid;grid-template-columns: auto auto auto auto;align-items: center;justify-content: center;margin-top: 50px;}
.brand-logos img {width: 90px;margin: 0 15px;}
.all-right-reserved {margin-top: 15px;}
.all-right-reserved > p {font-size: 12px;margin: 25px 0 0 0;text-align: center;}
.password-eye {float: right;margin-top: -48px;margin-right: 10px;}


/* forgot password  */
.forgot-password{float: right; text-decoration: underline; cursor: pointer;}
.back-btn >i{cursor: pointer;float: right;}
.export-box {padding: 25px;background: white; width: 500px; border-radius: 10px;}

/* Pop-up  */
.custom-container {position: fixed; left: 0; top: 0; width: 100%; height: 100vh; z-index: 99; display: grid; place-items: center;}
.custom-background {position: absolute; left: 0; top: 0; width: 100%; height: 100vh; z-index: 999; background: #00000050;}
.pop-up-form-custom {position: absolute; z-index: 9999; background: #FFF; padding: 40px 30px; width: 450px; opacity: 2; max-width: 100%; border-radius: 5px; overflow: hidden; text-align: center; overflow: hidden;}
.pop-up-form-custom img {width: 160px;}
.pop-up-form-custom h4 {margin: 15px 0 10px 0;font-size: 20px;font-weight: 500;}
.pop-up-form-custom p {color: #929191;}


/* Set new password */

.verify-mail {display: grid;height: 100vh;align-content: center;text-align: center;justify-items: center;}
.verify-mail img {width: 100px;}
.verify-mail h4 {font-weight: 500;margin-top: 20px;margin-bottom: 20px;}


.reset-password-container {
    background: #FFF;
    padding: 20px;
}
.reset-password-box {
    background: #6F6EB314;
    padding: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
.reset-password-box > div:nth-child(2) {
    text-align: center;
    padding-left: 20px;
}
.reset-password-box > div:nth-child(2) img {
    width: 350px;
    max-width: 100%;
}
.reset-password-box form {
    background: #FFF;
    padding: 30px;
    max-width: 600px;
}
.reset-password-box form h5 {
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid #E7E7E6;
    padding-bottom: 5px;
    margin-bottom: 15px;
}
.hide-show-password {
    margin-top: -46px;
    margin-right: 0.625rem;
    float: right;
    cursor: pointer;
}
.instructions {
    font-weight: 300;
    font-size: 12px;
}