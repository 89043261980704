img.brand-logo {
    max-height: 50px;
    max-width: calc(100% - 30px);
    margin: 15px 0 0 15px;
}
.left-menu-bar {
    height: 100vh;
    overflow: hidden;
}
.sidemenu-box ul {
    padding-left: 0;
    margin-top: 50px;
}
.sidemenu-box ul li > a {
    text-decoration: none;
    padding: 16px 20px;
    line-height: 1;
    display: grid;
    grid-template-columns: 20px auto;
    width: calc(100% - 40px);
    font-size: 16px;
    /* font-weight: 500; */
    border-radius: 1rem;
    margin: 0 20px;
    align-items: center;
    line-height: 1;
}
.sidemenu-box ul li > a:hover {
    color: inherit;
}
.sidemenu-box ul li a img {
    width: 15px;
}

.mobile-menu-items > div:first-child {padding: 10px 10px 10px 5px;display: grid;grid-template-columns: auto auto;justify-content: space-between;align-items: center;}
.mobile-menu-items > div:first-child > i {font-size: 40px;}
.mobile-menu {background: #FFF;padding: 10px 10px 10px 5px;display: grid;grid-template-columns: auto auto;justify-content: space-between;align-items: center;border-bottom: 1px solid #EEE;}
img.brand-mobile-logo {height: 50px;}
.mobile-menu i {font-size: 40px;}
.mobile-menu-items {background: #FFF;height: 100vh;position: absolute;z-index: 99;width: 100%;}

.menu-bars {
    width: 30px;
}
.menu-bars span {
    height: 5px;
    display: block;
    border-radius: 0.3rem;
}
.menu-bars span:nth-child(odd) {
    background-color: #212529;
}
.menu-bars span:nth-child(even) {
    background-color: #FFF;
}

.menu-times {
    width: 30px;
    height: 30px;
    display: grid;
    align-content: center;
}
.menu-times span {
    height: 5px;
    display: block;
    border-radius: 0.3rem;
    background-color: #212529;
}
.menu-times span:first-child {
    transform: rotate(45deg);
}
.menu-times span:last-child {
    transform: rotate(-45deg);
    margin-top: -5px;
}
.logout-modal {background-color: #FFF;padding: 25px;text-align: center;border-radius: 0.3rem;width: 450px;}
.logout-modal h4 {font-weight: 500; font-size: 24px;margin-bottom: 15px;}
.logout-modal h5 {margin-bottom: 25px; font-size: 18px; font-weight: 400;}
.modal-action{display: grid;grid-template-columns: auto auto;justify-content: space-between;}
.modal-action button {width: 190px;}
@media only screen and (max-width: 600px) {
    .left-menu-bar {display: none;}
}

@media only screen and (min-width: 601px) {
    .mobile-menu {display: none;}
}
