.search-box {background-color: #FFF;padding: 12px;margin-bottom: 10px;}
.search-box select {margin-bottom: 0;width: 300px;}
.pricing-head, .pricing-body {display: grid;grid-template-columns: 70px 3fr 2fr 220px 220px 70px;}
.pricing-head {padding: 15px 20px;font-weight: 500;background: #FAFAFA;font-size: 14px;}
.pricing-body {padding: 20px;font-weight: 400;background-color: #FFF;}
.pricing-body:nth-child(even) {background-color: #F5F5F5;}
.pricing-body p {margin-bottom: 0;}
.pricing-body p span {display: none;font-weight: 500;}
.pricing-body p:last-child {color: #FF7D90;font-weight: 500;cursor: pointer;}
.pricing-modal-container {position: absolute;left: 0;top: 0;width: 100%;height: 100vh;z-index: 99;display: grid;place-items: center;}
.pricing-modal-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh;z-index: 999;background: #00000050;}
.pricing-modal-box {position: absolute;z-index: 9999;background: #FFF;border: 1px solid #EEE;border-radius: 0.4rem;box-shadow: 0px 0px 15px 0px #0000001A;}
.pricing-modal-body {width: 800px;max-height: calc(90vh - 160px);overflow: hidden scroll;padding: 10px 30px;}
.pricing-modal-header {background-color: #FAFAFA;padding: 15px 30px;font-weight: 500;display: grid;grid-template-columns: auto auto;justify-content: space-between;align-items: center;}
.pricing-modal-header img {width: 20px;cursor: pointer;}
.pricing-modal-header h4 {font-size: 1.5rem;}
.pricing-modal-footer {padding: 20px 30px;border-top: 1px solid #EEE;display: grid;grid-template-columns: auto auto;justify-content: space-between;}
.small-input {display: grid;grid-template-columns: 1fr 1fr;}
.small-input div:nth-child(odd) {padding-right: 10px;}
.small-input div:nth-child(even) {padding-left: 10px;}
.textArea {display: block;border: 1px solid #CCC;border-radius: 3px;width: 100%;padding: 8px 10px;margin-bottom: 15px;background: transparent;}
.export-container {position: absolute; width: 100%; height: 100vh; z-index: 99; top: 0; left: 0; display: grid; place-items: center; }
.export-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh; z-index: 999;background: #00000050;}
.export-box {position: absolute; padding: 25px; z-index: 9999; background: #FFF; width: 500px; border-radius: 5px;}
.export-box h3 {font-size: 24px;}
.export-box img[alt="times"] {float: right;cursor: pointer;}
.export-box img[alt="download"] {margin-bottom: 20px;width: 110px;}
.export-box p {margin-bottom: 0;padding: 10px;font-weight: 500;background-color: #F9F9F9;border-bottom: 1px solid #EEE;display: grid;grid-template-columns: 1fr 1fr;text-align: right;}
.export-box p span {padding-left: 10px;text-align: left;color: #FF7D90;}
.export-buttons {display: grid;grid-template-columns: auto auto;justify-content: space-between;margin-top: 20px;}
.apiPrice-container{background: white; height: 100vh;}
.search-box > button{margin: 26px 0px;}


@media only screen and (max-width: 600px) {
    .our-api-services {background-color: transparent;}
    .edit-services {grid-template-columns: 1fr;}
    .edit-services div:nth-child(1), .edit-services div:nth-child(2) {padding-right: 0;}
    .api-search-box {padding: 20px 20px 5px 20px;margin-bottom: 15px;background-color: #FFF;}
    .api-menu {display: none;}
    .api-entries {grid-template-columns: 1fr;background-color: #FFF;border-bottom: 0;margin-bottom: 10px;padding: 0;border-radius: 5px;overflow: hidden;}
    .api-entries p {border-top: 1px solid #EEE;padding: 10px 15px;}
    .api-entries p:first-child {background: #EEE;font-weight: 500;width: max-content;padding: 8px 15px;}
    .api-entries p span {display: inline-block;}
    .pricing-head span{display: none;}
    .pricing-body {display: block; padding: 0px ; margin: 20px;box-shadow: 0px 0px 4px 0px #0000001F;}
    .pricing-body p:nth-child(1){background: #CCDDF3A6; padding: 15px; text-align: center;}
    .pricing-body p span{display: inline-block ; font-weight: 400; font-size: 14px;}
    .pricing-body:nth-child(even) {background-color: white ;}
    .pricing-body p:nth-child(2) span {margin-right: 80px;}
    .pricing-body p:nth-child(3) span {margin-right: 58px;}
    .pricing-body p:nth-child(4) span {margin-right: 53px;}
    .pricing-body p:nth-child(5) span {margin-right: 47px;}
    
    .pricing-body p{font-weight: 500; font-size: 14px; padding: 10px;}
    .search-box select {margin-bottom: 0;width: 250px;}
    .pricing-modal-container {position: absolute;left: 0;top: 0;width: 100%;height: 100vh;z-index: 99;display: grid;place-items: center;}
    .pricing-modal-body {width: 350px;}
    .export-box {width: 400px;}
}

.categoryOption {text-transform: capitalize;}   