.my-account-details-form {background-color: #FFF;padding: 25px;box-shadow: 0 0 25px 0 #0000001C;}
.tabs {list-style-type: none;border-bottom: 1px solid #CCC;font-weight: 500;user-select: none;}
.tabs li {display: inline-block;padding: 0 15px 10px 15px;cursor: pointer;}
.my-account-details-heading {   font-size: 20px;margin: 20px 0 20px 0;}
.verify-phone-option {color: #FE2343;cursor: pointer;margin-top: -15px;text-decoration: underline;font-size: 14px;}
.theme-logo {display: grid;grid-template-columns: 1fr 1fr;margin-bottom: 30px;padding: 30px 40px 40px 40px;background-color: #EFEFEF;}
.select-logo {border-radius: 0.5rem;border: 3px dashed #64717D;padding: 20px;text-align: center;background-color: #FFF;}
.select-logo img {width: 100px;}
.select-logo p span {display: block;padding: 10px;border-radius: 1px;border: 1px solid #607588;background: #eaf0f5cc;margin: 20px 0;cursor: pointer;}
.theme-logo > div:nth-child(odd) {margin-right: 20px;}
.theme-logo > div:nth-child(even) {margin-left: 20px;}
.theme-logo > div.logo-container {border-radius: 0.5rem;box-shadow: 0 1px 4px 0 #00000033;border: 3px dashed transparent;padding: 20px;display: grid;place-items: center;background-color: #FFF;}
.theme-logo > div.logo-container > img {width: auto;height: auto;max-width: 200px;max-height: 50px;}
.theme-color {display: grid;grid-template-columns: 60px 1fr;}
.theme-color > input {margin-bottom: 0!important;}
.theme-color > input[type="color"] {height: 100%;margin-right: 10px;}
.password-eye {float: right;margin-top: -48px;margin-right: 10px;cursor: pointer;}
/* .basic-page-form{display: grid; grid-template-columns: auto auto; align-items: center;} */
.basic-page-form {margin: 20px 0;}
/* .basic-page-form>div:first-child{margin-right: 10px;} */
.termCondition-modal h2 {font-weight: 600; font-size: 24px;margin-bottom: 15px;}
.termCondition-modal {background-color: #FFF;padding: 25px;text-align: center;border-radius: 0.3rem;width: 450px; height: 580px; overflow: auto;}
.termCondition-modal h5 {margin-bottom: 25px; font-size: 18px; font-weight: 400;}
.modal-action{display: grid;grid-template-columns: auto auto;justify-content: space-between;}
.modal-action button {width: 190px;}
.razorpay-modal-box {width: 70vw;max-height: 90vh;background: #FFF;border: 1px solid #EEE;border-radius: 0.4rem;box-shadow: 0 0 15px 0 #0000001A;padding: 20px;}
.edit-modal-details-heading {font-size: 20px;margin: 20px 0 20px 0;padding-bottom: 10px;border-bottom: 1px solid #CCC;}
.favicon-uploader{display: grid; grid-template-columns: 1fr 1fr;padding: 30px 40px 40px 40px;background-color: #EFEFEF;}
.uploading-box{border-radius: 0.5rem;border: 3px dashed #64717D;padding: 20px;text-align: center;background-color: #FFF;margin: 0 20px 0 0;}
.uploading-box p span {display: block;padding: 10px;border-radius: 1px;border: 1px solid #607588;background: #eaf0f5cc;margin: 20px 0;cursor: pointer;}
.preview-box {margin: 0 0 0 20px;}
.preview-favicon {border-radius: 0.5rem;box-shadow: 0px 1px 4px 0px #00000033;border: 3px dashed transparent;padding: 20px;display: grid;place-items: center;background-color: #FFF;}
.preview-favicon > img {width: auto;height: auto;max-width: 200px;max-height: 50px;}
.progress-modal {background-color: #FFF;border-radius: 0.5rem;padding: 20px;width: 400px;text-align: center;}
.progress-modal h4 {margin-top: 10px;font-weight: 500;text-transform: uppercase;}
.progress-modal span {color: #AAA;font-size: 30px;}
.invalid-url {color: red;font-size: 14px;margin-left: 10px;}
.setting-options {margin-bottom: 10px;}
.setting-options > div {display: flex;justify-content: space-between;align-items: center;padding: 15px;border-bottom: 1px solid #EEE;font-size: 14px;}
.setting-options > div:first-child {border-top: 1px solid #EEE;}
.setting-options > div > p {font-weight: 500;}
.setting-options > div > div {display: flex;gap: 20px;}
.setting-options > div > div > span {padding: 5px 15px;border-radius: 1rem;cursor: pointer;background-color: #EAF0F560;}
.setting-options > div > div > span:hover {background-color: #EAF0F5;}
.setting-options > div > div > input[type="text"] {margin-bottom: 0px!important;width: 450px;}
.terms-container {position: absolute; width: 100%; height: 100vh; z-index: 99; top: 0; left: 0; display: grid; place-items: center; }
.terms-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh; z-index: 999;background: #00000050;}
.terms-box {position: absolute; padding: 20px; z-index: 9999; background: #FFF; width: 800px; border-radius: 0.5rem;overflow: hidden;}
.terms-and-conditions-heading {background-color: #EEE;padding: 15px 20px;margin: -20px 0 20px -20px;width: calc(100% + 40px);font-weight: 500;font-size: 18px;}
.terms-and-conditions-note {background-color: #FCF6E8;padding: 10px 15px;font-size: 14px;border-radius: 0.5rem;margin: 15px 0;font-weight: 500;color: #E19C16;}

@media only screen and (max-width: 900px) {
    .basic-page-form{display: block;}
    .basic-page-form > div:first-child{width: auto;}
    .razorpay-modal-box {width: fit-content}
}
