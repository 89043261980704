.whitelist-head, .whitelist-body {display: grid; column-gap: 5px; grid-template-columns: 50px 1fr 1fr 1fr 1fr;}
.whitelist-head {padding: 15px 20px;background: #FAFAFA;font-weight: 500;font-size: 14px;}
.whitelist-body {padding: 20px;background-color: #FFFFFF;border-top: 1px solid #C4C4C4DB;}
.whitelist-head > p, .whitelist-body > p {margin-bottom: 0;}
/* .whitelist-body > p:last-child {cursor:not-allowed ; text-decoration: underline;} */
.whitelist-box {box-shadow: 0px 0px 15px 0px #0000001A;}
.whitelist-box > div:nth-child(2) {border-top: 0;}
.whitelist-box > div > p > span {display: none;font-weight: 500;}

.modal-container {position: fixed;left: 0;top: 0;width: 100%;height: 100vh;z-index: 99;display: grid;place-items: center;}
.modal-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh;z-index: 999;background: #00000050;}
.modal-box {position: absolute;z-index: 9999;background: #FFF;border: 1px solid #EEE;border-radius: 0.3rem;box-shadow: 0px 0px 15px 0px #0000001A;width: 500px;}
.image-text{display: grid; grid-template-columns: 1fr 50px; align-items: center; }
.modal-header {background-color: #FAFAFA;padding: 15px 30px;font-weight: 500;display: grid;grid-template-columns: auto auto;justify-content: space-between;align-items: center;}
.cross-image{cursor: pointer; background-color: #FAFAFA; padding:15px 0px}
.modal-body {padding: 10px 30px;}
.modal-footer {padding: 20px 30px;border-top: 1px solid #EEE;display: grid;grid-template-columns:auto auto;justify-content: space-between;}
.no-records {text-align: center;font-weight: 500;color: #424242; padding:30px 0px}

.whitelist-filter {display: grid; grid-template-columns: auto auto; justify-content: space-between; align-items: center;}
.whitelist-add {margin-bottom: 10px;}
.filter-action {cursor: pointer;}

/* Filter Pop-up starts*/
.filter-icon [alt = 'times'] {cursor: pointer;}
.filter-icon span{display: grid; grid-template-columns: 20px 1fr 30px; align-items: center;}
.filter-container {position: absolute; width: 100%; height: 100vh; z-index: 99; top: 0; left: 0; place-items: right; }
.filter-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh; z-index: 999;background: #00000050;}
.clear-filter {float: right;margin-top: 26px;color: #FF7D90;font-weight: 500;font-size: 18px;text-decoration: underline;cursor: pointer;}
.filter-form{position: absolute; padding: 25px; z-index: 9999; right: 0; height: 100vh; background: #FFF; width: 400px; max-width: 100%; border-radius: 5px;}
/* Filter Pop-up ends*/

@media only screen and (max-width: 1450px) {
    .whitelist-head, .whitelist-body {grid-template-columns: 50px 300px 180px 180px 220px;}
}

@media only screen and (max-width: 600px) {
    .whitelist-box {box-shadow: none;}
    .whitelist-box > div:first-child {display: none;}
    .whitelist-box > div:first-child {display: none;}    
    .whitelist-box > div {background-color: #FFF;border-bottom: 0;margin-bottom: 10px;padding: 0;border-radius: 5px;overflow: hidden;} 
    .whitelist-box > div {grid-template-columns: 1fr;}

    .whitelist-box > div p {border-top: 1px solid #EEE;padding: 10px 15px;}
    .whitelist-box > div p:first-child {background: #EEE;font-weight: 500;width: max-content;padding: 8px 15px;}
    .whitelist-box > div p span {display: inline-block;color: #1C2347;}
    .modal-box {width: 90%;}
    .modal-footer {display: block;padding: 10px 30px;}
    .modal-footer > div {margin: 10px 0;}
    .whitelist-box > div p span {display: inline-block;color: #1C2347;}
}