.whitelist-head, .whitelist-body {display: grid; column-gap: 5px; grid-template-columns: 50px 1fr 1fr 1fr;}
.whitelist-head {padding: 10px 20px;background: #FAFAFA;font-weight: 500;font-size: 14px;}
.whitelist-body {padding: 10px 20px;background-color: #FFFFFF;border-top: 1px solid #C4C4C4DB;}
.whitelist-head > p, .whitelist-body > p {margin-bottom: 0;}
/* .whitelist-body > p:last-child {cursor:not-allowed ; text-decoration: underline;} */
.whitelist-box {box-shadow: 0px 0px 15px 0px #0000001A;}
.whitelist-box > div:nth-child(2) {border-top: 0;}
.whitelist-box > div > p > span {display: none;font-weight: 500;}
.no-records {text-align: center;font-weight: 500;color: #424242; padding:30px 0px}

@media only screen and (max-width: 600px) {
    .whitelist-box {box-shadow: none;}
    .whitelist-box > div:first-child {display: none;}
    .whitelist-box > div {background-color: #FFF;border-bottom: 0;margin-bottom: 10px;padding: 0;border-radius: 5px;overflow: hidden;}
    .whitelist-box > div {grid-template-columns: 1fr;}
    .whitelist-box > div p {border-top: 1px solid #EEE;padding: 10px 15px;}
    .whitelist-box > div p:first-child {background: #EEE;font-weight: 500;width: max-content;padding: 8px 15px;}
    .whitelist-box > div p span {display: inline-block;color: #1C2347;}
    .modal-box {width: 90%;}
}