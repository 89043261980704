.statement-verification-card {margin-bottom: 20px;background-color: #FFF;border-radius: 0.5rem;box-shadow: 0px 0px 15px 0px #0000001A;padding-right: 10px;padding: 25px;display: grid;grid-template-columns: 90px auto 200px;align-items: center;}
.statement-verification-card > div > img {width: 70px;}

.statement-details {display: grid;grid-template-columns: 2fr 3fr;justify-content: space-between;}
.statement-details p span {display: block;margin-left: 0;font-weight: 600;font-size: 16px;word-break: break-all;}
.statement-details > p {font-weight: 400;margin: 5px 0;}

.statement-buttons {padding-left: 20px;}
.statement-buttons button {width: 100%;}
.statement-buttons span button {margin-top: 20px;}

.change-status-container {position: absolute;left: 0;top: 0;width: 100%;height: 100vh;z-index: 99;display: grid;place-items: center;}
.change-status-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh;z-index: 999;background: #00000050;}
.change-status-box {position: absolute;z-index: 9999;width: 800px;border-radius: 5px;background-color: #FFF;padding: 20px;}
.change-status-box p {font-family: 'Rubik';font-weight: 400;font-size: 18px;margin-bottom:0;color: #929191;}
.change-status-box img[alt="times"] {position: absolute;right: 0;margin: 0px 20px;cursor: pointer;}
.change-status-box button {width: 100%;margin-top: 20px;}
.image-status {display: grid;grid-template-columns: 1fr 1fr;}
.right-content h5 {font-weight: 600;font-size:20px;color: #1C2347;margin-bottom: 10px;}
.change-status-icon {padding-right: 15px;}
.right-content > div {margin-bottom: 7px;}

@media only screen and (max-width: 1420px) {
    .statement-details {grid-template-columns: 1fr 2fr;}
}

@media only screen and (max-width: 1350px) {
    .statement-details {grid-template-columns: 1fr;}
}

@media only screen and (max-width: 1140px) {
    .statement-verification-card {grid-template-columns: 1fr;}
    .statement-verification-card > div > img {margin-bottom: 10px;}
    .statement-buttons {padding-left: 0;display: grid;grid-template-columns: 1fr 1fr;}
    .statement-buttons button {margin-top: 20px;}
    .statement-buttons a {padding-right: 10px;}
    .statement-buttons span {padding-left: 10px;}
}

@media only screen and (max-width: 840px) {
    .statement-verification-card {grid-template-columns: 1fr;}
}

@media only screen and (max-width: 720px) {
    .statement-verification-card {grid-template-columns: 1fr;}
    .statement-buttons {padding-left: 0;grid-template-columns: 1fr;}
    .statement-buttons a {padding-right: 0;}
    .statement-buttons span {padding-left: 0;}
}