.UserContainer {border: 1px solid #D1DFFF;margin-top: 20px;}
.back-to-clients {padding: 5px 10px;}
.back-to-clients span {cursor: pointer;color: #2D64BF;font-size: 14px;font-weight: 500;}
.sideMenu {border-right: 1px solid #EEE; border-bottom: none;font-size: 14px;font-weight: 500;}
.sideMenu p {padding: 15px 20px;cursor: pointer;border-bottom: 1px solid #EEE;}
.sideMenu p:last-child {border-bottom: 0;}
.sideMenu img {padding: 20px 20px 20px 40px;}
.sideMenu > p:not(:first-child) {border-bottom:1px solid #D9D9D9;color: #777;}
.menu-option{display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;}
.client-details {display: grid;grid-template-columns: 90px 200px 250px 250px 280px 315px 250px;background: #FFF;padding: 10px 20px;border: 1px solid #D1DFFF;align-items: center;}
.client-details p {padding: 10px 20px;font-weight: 500;color: #8393AE; font-size: 14px;}
.client-details p span {font-weight: 500;color: initial;display: block;}
.client-details img{width: 15px;}
.client-details h3{padding: 15px; background-color: #D2E1F9; color:#1C2347;display: grid;justify-content: center;font-size: 20px;font-weight: 600;}
.menu-active {color:#2D64BF !important;}
.menu-active > span {float: right; font-size: 18px;}
.menu-active-not{color: #8393AE;}

.detail-card h4 {padding: 15px 15px;font-size: 18px;font-weight: 500;}
.detail-card h4 span {color: #6091E2;font-size: 14px;font-weight: 500;}

.allAdminInfo {padding: 0 10px;margin: 0 10px;}
.menu {float: right;}
.showmenu {display: none;}

/*********** API Price Begins  ************/
.api-price-page {height: calc(100vh - 338px);overflow-y: scroll;}
.api-price-page::-webkit-scrollbar{width: 0;}
.api-price-table > div {display: grid;grid-template-columns:  50px 2fr 1fr 1fr 1fr 110px; column-gap: 8px;padding: 10px 20px;align-items: center;background: #FFF;border-bottom: 1px solid #C4C4C4DB; word-break: break-all;font-size: 14px;}
.api-price-table > div > p > span {display: none;color: #1C2347;}
.api-price-table > div > p > input {margin-bottom: 0;}
.api-price-table > div:first-child {background: #8393AE1A;font-weight: 500;border-bottom: 0px;font-size: 14px;}
.api-price-table > div:last-child {border-bottom: 0px;}
.api-price-action {height: 70px;padding-right: 20px;background: #EEE;display: grid;align-items: center;justify-items: end;}
.api-price-action button {width: 150px;}
/*********** API Price End  ************/

/*********** Admin users begins  ************/
.admin-users {display: grid;grid-template-columns: 50px 1fr 160px;align-items: center;box-shadow: 0px 0px 4px 0px #0000001F;margin-bottom: 15px;padding-right: 15px;gap: 10px;}
.admin-users-details {display: grid; grid-template-columns: 150px 1fr 120px 120px 80px 120px;gap: 10px;font-size: 14px;}
.admin-users-details p span {display: block;font-weight: 500;}
.admin-users-details p.status_active {color: #24B056;}
.admin-users-details p.status_inactive {color: red}
.admin-users-details p.status_active span, .admin-users-details p.status_inactive span {color: initial;}
.admin-users > p:nth-child(1) {background: #CCDDF3A6;height: 80px;display: grid;place-items: center;}
/*********** Admin users End  ************/

/***********    Add Credit Begins  ************/
.credit-outer-container {padding: 0px 10px;margin: 0px 10px; overflow-x: auto;}

.credits-account {padding: 20px;}
.available-credits {display: grid; grid-template-columns: 200px 50px 1fr;}
.add-credits {display: grid; grid-template-columns: 200px 50px 1fr 190px; align-items: baseline; margin-bottom: 0;}
.available-credits >:first-child {font-weight: 500;font-size: 16px;color: #1C2347;}
.available-credits :nth-child(2) {font-weight: 700;font-size: 16px;color: #000000;}
.available-credits :nth-child(3) {font-weight: 700;font-size: 16px;} 
.add-credits > :first-child {font-weight: 500;font-size: 16px;color: #1C2347;}
.add-credits :nth-child(2) {font-weight: 500;font-size: 16px;color: #000000;}
.add-credits :nth-child(3) {width: 500px;}
.api-wallet {background-color: #FAFAFA;padding: 15px 40px;font-weight: 600;font-size: 18px;margin-bottom: 20px;}
.api-credit-tab {display: grid; grid-template-columns: repeat(2, 400px);margin-bottom: 15px;}
.balance-tab {border: 2px solid #EEE;border-radius: 0.5rem;padding: 20px;font-weight: 500;margin-left: 15px;}
.balance-tab span {font-weight: 700;font-size: 50px;}
.balance-tab h3 {font-weight: 500;font-size: 20px;margin-bottom: 0;}
.credit-tab {border: 2px solid #eee; border-radius: 0.5rem; padding: 20px;font-weight: 500;margin-left: 15px;}
.credit-tab h3 {font-weight: 500;font-size: 20px;}
.credit-tab button {background-color: #FF7D90;color: #FFF;padding: 7px 50px;border-radius: 0.3rem;display: block;margin:20px 0px}


/***********    Add Credit End     ************/

/***********    Documentation Link Begins  ************/
.documentation-heading {padding: 15px 15px;font-size: 18px;font-weight: 500;border-bottom: 4px solid #F9F9F9;}
.basic-page-form {margin: 20px 0;padding: 40px 20px;}
.basic-page-form button {background-color: #2D64BF;border: 1px solid transparent;font-size: 1rem;border-radius: 0.25rem;padding: 8px 38px;color: #FFF;cursor: pointer;}
.documentation-body {padding: 0px 10px; margin: 0px 10px;}
.documentaion-body-content {box-shadow: 0 0 25px 0 #0000001C;}


@media only screen and (max-width: 900px) {
    .basic-page-form {display: block;}
    .basic-page-form > div:first-child{width: auto;}
}
/***********     Documentation Link Ends   ************/

/***********    Setting Link Begins  ************/
.setting-grid {display: grid; grid-template-columns: 1fr 300px 50px; font-weight: 500; padding: 15px 40px; border-top: 1px solid hwb(0 77% 23% / 0.376); border-bottom: 1px solid hwb(0 77% 23% / 0.376)}
.setting-radio-btn {display: grid; grid-template-columns: auto auto;}
.setting-grid  span {padding: 6px;}
.setting-grid > span:nth-child(1) {font-size: 18px;}


.export-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 99;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
}
.export-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    background: #00000050;
}
.export-box {
    position: absolute;
    padding: 25px;
    z-index: 9999;
    background: #fff;
    width: 500px;
    border-radius: 5px;
}
.export-box h3 {
    font-size: 24px;
}
.export-box img[alt='times'] {
    float: right;
    cursor: pointer;
}
.export-box img[alt='download'] {
    margin-bottom: 20px;
    width: 110px;
}
.export-box p {
    margin-bottom: 0;
    padding: 10px;
    font-weight: 500;
    background-color: #f9f9f9;
    border-bottom: 1px solid #eee;
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: right;
}
.export-box p span {
    padding-left: 10px;
    text-align: left;
    color: var(--primary-color);
}
.export-buttons {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    margin-top: 20px;
}


@media only screen and (max-width: 800px) {
    .setting-grid {grid-template-columns: 1fr;}
}
/***********     Setting Link Ends   ************/

@media only screen and (max-width: 1600px) {
    .admin-users-details {grid-template-columns: 2fr 2fr 1fr;}
    .admin-users-details p {padding: 5px 0;}
    .admin-users > p:nth-child(1) {height: 100%;}
}

@media only screen and (max-width: 1350px) {
    .admin-users-details {grid-template-columns: 2fr 3fr;}
    .api-credit-tab {grid-template-columns: 1fr 1fr;}
}

@media only screen and (max-width: 1150px) {
    .admin-users {grid-template-columns: 1fr;padding: 0 15px 15px 15px;}
    .admin-users > p:nth-child(1) {margin-left: -15px;width: calc(100% + 30px);padding: 15px;}
    .api-price-table > div {display: block;padding: 0;border-bottom: 0px; margin:10px 10px; box-shadow: 0px 0px 4px 0px #0000001F;}
    .api-price-table > div > p {padding: 10px 20px;border-bottom: 1px solid #EEE;color: #777;}
    .api-price-table > div > p:last-child {border-bottom: 0px solid #C4C4C4DB; }
    .api-price-table > div > p > span {display: inline; }
    .api-price-table > div:first-child {display: none;}
    .api-price-table > div > p > input {margin-bottom: 0px !important;}
}

@media only screen and (max-width: 1010px) {
    .admin-users-details {grid-template-columns: 1fr;}
    .balance-tab span {font-weight: 600;font-size: 36px;}
}

@media only screen and (max-width: 600px) {
    .UserContainer{display: block;}
    .admin{grid-template-columns: 1fr;gap:0px}
    .admin > div:nth-child(1){text-align: start; display: inline-block; padding:8px 15px;width: max-content;}
    .admin > div{display: flex;}
    .admin>div>p{font-size: 12px;font-weight: 500; padding: 8px 15px} 
    .admin>div{border-top: 1px solid #eee;}
    .admin>img{padding: 8px 15px} 
    .admin>div>span {font-size: 14px;font-weight: 400; padding: 8px 15px; }

    .admin div:nth-child(3) > p {margin-right: 50px;}
    .admin div:nth-child(4) > p {margin-right: 50px;}
    .admin div:nth-child(5) > p {margin-right: 27px;}
    .admin div:nth-child(6) > p {margin-right: 40px;}
    .admin div:nth-child(7) > p {margin-right: 47px;}
    .admin div:nth-child(8) > p {margin-right: 37px;}

    .showmenu{display: inline-block;}
    .menu-option{display: flex; overflow-x: scroll; margin: 0px 10px;border-bottom:1px solid #D9D9D9;}
    .menu-active > span {display: none;}
    .sideMenu p {padding:10px 15px;margin:10px 0;cursor: pointer;margin-bottom: 20px;}
    .sideMenu img {padding: 20px;}
    .sideMenu {height: auto;}

    .api-price-page {height: calc(100vh - 460px);}
    .client-details{display: block;}
}