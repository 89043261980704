.client-container {padding: 15px; color: #1C2347;}
.search-box {display:grid;grid-template-columns: 1fr auto;align-items: center;margin-bottom: 10px}
.search-box > span:first-child {font-weight: 600;font-size: 18px;} 
.search-box > span:last-child {cursor: pointer;font-weight: 500;color: #6091E2;font-size: 18px}

.container-head, .container-body {display: grid;grid-template-columns: 40px 150px 180px 1fr 1fr 1fr 150px; column-gap: 10px; align-items: center;}
.container-head p:first-child, .container-body p:first-child {text-align: center;}
.container-head {background-color: #8393AE1A;column-gap: 10px;padding: 10px 15px;font-size: 14px;border: 1px solid #D1DFFF;color: #8393AE;}
.container-body {background-color: #FFFFFF;column-gap: 10px;padding: 15px 15px;font-size: 14px;box-shadow: 0px 0px 4px 0px #0000001F;border: 1px solid #D1DFFF}
.container-body p:last-child{color: #FF7D90; cursor: pointer; text-decoration: underline;}
.container-body span {display: none;}

.filter-container {position: absolute; width: 100%; height: 100vh; z-index: 99; top: 0; left: 0; place-items: right; }
.filter-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh; z-index: 999;background: #00000050;}
.filter-form{position: absolute; padding: 25px; z-index: 9999; right: 0; height: 100vh; background: #FFF; width: 400px; max-width: 100%; border-radius: 5px;}
.filter-icon span{display: grid; grid-template-columns: 20px 1fr 30px; align-items: center;}
.filter-icon [alt = 'times'] {cursor: pointer;}
.clear-filter {float: right;margin-top: 26px;color: #FF7D90;font-weight: 500;font-size: 18px;text-decoration: underline;cursor: pointer;}
/* .card-container {padding: 15px; border: 1px solid #D9D9D9; width: 400px; border-radius: 0.3rem;}
.card-container > h5 {font-size: 20px;font-weight: 500;color: #1C2347;margin-bottom: 5px;}
.card-container > p {font-size: 16px;color: #777777;}
.card-container > button {width: 100%;}
.card-info {padding: 10px 15px; background-color:#F6F6F6; margin: 10px 0px; color: #777; font-size: 12px; display: grid; grid-template-columns: 2fr 3fr; border-radius: 0.3rem;}
.card-info > p {color: #777777;font-size: 14px;margin: 5px 0;word-break: break-all;padding-right: 5px;}
.card-info > p > span {color: #1C2347;font-size: 16px;font-weight: 500;display: block;} */

@media only screen and (max-width: 1700px) {
    .container-head, .container-body {grid-template-columns: 30px 85px 250px 1fr 250px 1fr 200px; word-break: break-all;}
}
@media only screen and (max-width: 1500px) {
    .container-head, .container-body {grid-template-columns: 30px 85px 250px 1fr 250px 1fr 200px; word-break: break-all;}
}

@media only screen and (max-width: 1400px) {
    .container-head {display: none;}
    .container-body {grid-template-columns: 2fr 3fr 2fr;}
    .container-body p {padding: 10px 5px}
    .container-body p:first-child {text-align: left;}
    .container-body img {display: none !important;}
    .container-body p {padding: 10px 5px;border-bottom: 1px solid #EEE;}
    .container-body p:last-child {border-bottom: 0;}
    .container-body span {font-weight: 600;display: inline-block;}
}

@media only screen and (max-width: 1000px) {
    .container-body {grid-template-columns: 1fr 1fr;}
    .container-body span {display: block;}
}

@media only screen and (max-width: 800px) {
    .container-body {display: block;padding: 10px;}
    .container-body p {padding: 10px 5px}
    .container-body span {display: inline-block;}
}

@media only screen and (max-width: 600px) {
    .filter-form {height: 100%;width: 100%;border-radius: 0;}
}