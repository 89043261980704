.sidebar {
    background: #182433;
    height: 100%;
}
.sidebar li a {
    color: #8393AE;
    text-align: center;
    padding: 20px;
    font-weight: 500;
    display: block;
    text-align: justify;
    text-decoration: none;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

.sidebar li i {
    color: #8393AE;
}
.sidebar li.active a {
    background: #6091E2;
    color: #fff;
}
.sidebar li.active i {
    color: #FFF;
}
.sidebar li.active a:hover {
    color: #FFF;
}

@media only screen and (max-width: 600px) {
    .sidebar ul {
        border-top: 1px solid #EEE;
    }
    .sidebar li a {
        text-align: left;
    }
    .sidebar li i {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 0;
    }
}
