.message-container {text-align: center;position: fixed;z-index:9999;top: 20px;left: 0;width: 100%;}
.message-container > div {margin: 0;text-align: left;background:#FFF;min-width: 350px;max-width:calc(100% - 20px);display: inline-block;padding: 18px 15px;border-radius: 5px;overflow: hidden;box-shadow: 0px 0px 18px 0px #0000001A;}
.message-container > div > div {display: grid;grid-template-columns: 55px 1fr 80px;align-items: center;}
.message-container > div > div > div {border-right: 1px solid #BEBFBD;padding-right: 20px;}
.message-container img {width: 40px;}
.message-container h4, .message-container h5, .message-container p {margin: 0;}
.message-container h4 {font-weight: 500!important;font-size: 22px!important;margin-bottom: 4px;padding: 0!important;border-bottom: 0px;}
.message-container h5 {color: #777777;font-size: 16px;}
.message-container p {color: #BEBFBD;font-size: 16px;cursor: pointer;text-align: center;padding-left: 15px;transition: 0.3s;}
.message-container p:hover {color: #999;}
.error-message-box {border-left: 8px solid #FE2343;}
.success-message-box {border-left: 8px solid #24B056;}

@media only screen and (max-width: 600px){
    .message-container {top: 10px;}
    .message-container > div {box-shadow: 0px 0px 18px 0px #00000055;padding: 10px 5px;}
    .message-container > div > div {grid-template-columns: 45px 1fr 60px;}
    .message-container > div > div > div {padding-right: 10px;}
    .message-container p {padding-left: 0;}
}
