.login-page {
    background-image: url('./background.png');
    height: 100vh;
    padding: 50px;
    display: grid;
    place-items: center;
    background-size: 100% 100%;
}
.login-box {
    background-color: #FFF;
    padding: 50px 0;
    display: grid;
    grid-template-columns: auto auto;
    border-radius: 0.3rem;
    align-items: center;
}
.login-box > div:first-child {
    border-right: 1px solid #DDD;
    height: 100%;
    padding: 80px;
}
.login-box > div:first-child img {
    width: 400px;
}
.login-box > div:last-child {
    padding: 10px 40px;
}
.login-box > div:last-child form {
    width: 400px;
    overflow: hidden;
}
.login-box > div:last-child form {
    width: 400px;
    overflow: hidden;
}
.login-box > div:last-child img {
    width: 220px;
}
.login-box > div:last-child h5 {
    font-size: 20px;
    font-weight: 600;
    margin: 25px 0 10px 0;
}
.hide-show-password {
    float: right;
    margin-top: -48px;
    margin-right: 10px;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .login-page {
        padding: 30px;
    }
    .login-box {
        grid-template-columns: auto;
        padding: 20px 0;
    }
    .login-box > div:first-child {
        border-right: 0;
        padding: 20px;
    }
    .login-box > div:first-child img {
        width: 100%;
    }
    .login-box > div:last-child {
        padding: 10px 20px;
    }
    .login-box > div:last-child form {
        width: 100%;
    }
}