.transactions-box {box-shadow: 0px 0px 15px 0px #0000001A;}
.transactions-box > div {padding: 20px;background-color: #FFFFFF;border-bottom: 1px solid #C4C4C4DB;}
.transactions-box > div:first-child {padding: 15px 20px;background: #FAFAFA;font-weight: 500;font-size: 14px;}
.transactions-box > div:last-child {border-bottom: 0;}
.transactions-box > div > p {margin-bottom: 0;}
.transactions-box > div > p > span {display: none;font-weight: 500;}
.transactions-box > div > p > span.active {color: green; display: block;}
.transactions-box > div > p > span.blocked {color: red; display: block;}
.transactions-box p.block {color: #F0393A;cursor: pointer;font-weight: 500;}

.all-list-head, .all-list-body {display: grid;grid-template-columns: 50px 1fr 1fr 130px 1fr 140px 130px 140px; column-gap: 10px; align-items: center;}
.blocked-list-head, .blocked-list-body {display: grid;grid-template-columns: 50px 1fr 1fr 1fr 1fr ; column-gap: 10px;}
.no-data {display: block;text-align: center;border-bottom: 0px;}

.tabs {list-style-type: none;padding-left: 0;margin-bottom: 30px;}
.tabs li {display: inline-block;font-weight: 500;padding: 0 10px 2px 10px;border-bottom: 3px solid transparent;cursor: pointer;}
.tabs li.selected {color: #FF7D90;border-color: #FF7D90;}
.tab-menu{display: grid;grid-template-columns: auto auto;cursor: pointer;justify-content: space-between;}
.filter-action {cursor: pointer;font-weight: 500;color: #FF7D90;font-size: 18px;}

.modal-container {position: fixed;left: 0;top: 0;width: 100%;height: 100vh;z-index: 99;display: grid;place-items: center;}
.modal-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh;z-index: 999;background: #00000050;}
.modal-box {position: absolute;z-index: 9999;background: #FFF;border: 1px solid #EEE;border-radius: 0.3rem;box-shadow: 0px 0px 15px 0px #0000001A;width: 500px;}

.modal-header {background-color: #FAFAFA;padding: 15px 30px;font-weight: 500;display: grid;grid-template-columns: auto auto;justify-content: space-between;align-items: center;}
.modal-body {padding: 10px 30px;}
.modal-footer {padding: 20px 30px;border-top: 1px solid #EEE;display: grid;grid-template-columns: 180px 180px;justify-content: space-between;}
.modal-footer div button{width: 100%;}

.active-block button{width:100%;}

/* Filter Pop-up */
.filter-icon [alt = 'times'] {cursor: pointer;}
.filter-icon span{display: grid; grid-template-columns: 20px 1fr 30px; align-items: center;}
.filter-container {position: absolute; width: 100%; height: 100vh; z-index: 99; top: 0; left: 0; place-items: right; }
.filter-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh; z-index: 999;background: #00000050;}
.clear-filter {float: right;margin-top: 26px;color: #FF7D90;font-weight: 500;font-size: 18px;text-decoration: underline;cursor: pointer;}
.filter-form{position: absolute; padding: 25px; z-index: 9999; right: 0; height: 100vh; background: #FFF; width: 400px; max-width: 100%; border-radius: 5px;}

/*  Unblock Pop-up */
.heading-icon{display: grid; grid-template-columns: 1fr 25px; margin-bottom: 15px; cursor: pointer;}
.image-content {text-align: center;}
.bottom-buttons button{width: 100%;}
.bottom-buttons{display: grid; grid-template-columns: 170px 170px; justify-content: space-between; margin-top: 20px;}
.blockip-container {position: absolute; width: 100%; height: 100vh; z-index: 99; top: 0; left: 0; display: grid; place-items: center; }
.blockip-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh; z-index: 999;background: #00000050;}
.blockip-box {position: absolute; padding: 25px; z-index: 9999; background: #FFF; width: 500px; border-radius: 5px;}
.blockip-box h3{font-weight: 600; font-size: 20px; text-align: center; margin-bottom: 20px; margin-top: 30px;}
.view-detail-box {font-weight: 500;}

@media only screen and (max-width: 1450px) {
    .all-list-head, .all-list-body{grid-template-columns: 30px 140px 140px 110px 1fr 100px 130px 140px;}
}
@media only screen and (max-width: 1050px) {
    .blocked-list-head, .blocked-list-body {grid-template-columns: 50px 200px 130px 180px 1fr ;}
}

@media only screen and (max-width: 650px) {
    .all-list-body{grid-template-columns: 1fr}
    .transactions-box  {box-shadow: none;}
    .transactions-box > div  {border-radius: 5px; margin-bottom: 10px;}
    .transactions-box > div > p > span {display: inline-block;}
    .transactions-box > div p:first-child {background: #EEE;font-weight: 500;width: max-content;padding: 8px 15px;}
    .transactions-box > div p {border-top: 1px solid #EEE;padding: 10px 15px;}
    .all-list-head{display: none;}

    .blocked-list-head{display: none;}
    .blocked-list-body {grid-template-columns: 1fr ;}


}
